import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { usePageTitle } from '../../logic';
import { LoadingScreen } from '../shared';

export function RegisterUser({auth0TicketId, companyIdOrderIdHash}: {
  auth0TicketId?: string
  companyIdOrderIdHash?: string
}) {
  usePageTitle('Connecting to your transaction... counting shiplap walls');
  const { loginWithRedirect } = useAuth0();

  // This is for a user using the invite link to register.
  // if there's an auth0TicketId, it means they had a mapping that matched the
  // invitationKey that was valid etc and we returned the auth0TicketId
  // for them to create their account
  if(auth0TicketId){
    loginWithRedirect({
      invitation: auth0TicketId,
      appState: {
        returnTo: `/closings/${companyIdOrderIdHash}`
      }
    });
    return <LoadingScreen/>;
  }
  else{
    let to;

    if(companyIdOrderIdHash){
      to = `/closings/${companyIdOrderIdHash}`;
    }
    else{
      to = "/closings/";
    }
    return <Navigate to={to} />;
  }
}
