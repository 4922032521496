export function getScopes(MfaRequired?: boolean) {
  return ''
  // if (MfaRequired) {
  //   return 'enroll remove:authenticators read:authenticators'
  // } else {
  //   return ''
  // }
}

export function getAudience(MfaRequired?: boolean) {
  return `https://${process.env.REACT_APP_AUTH0_DOMAIN_FOR_MFA_CLAIM!}/api/v2/`
  // if (MfaRequired) {
  //   return `https://${process.env.REACT_APP_AUTH0_DOMAIN_FOR_MFA_CLAIM!}/mfa/`
  // } else {
  // }
}
