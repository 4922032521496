import { Auth0Provider, Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PortalDomainOrg } from '../types';
import { getAudience, getScopes } from './auth0Utils';
import { useLocalStorage } from './useLocalStorage';

export const Auth0ProviderWithHistory = ({ children, organization, companyIdOrderIdHash }: {
  children: any;
  organization: PortalDomainOrg;
  companyIdOrderIdHash?: string | undefined;
}) => {
  const navigate = useNavigate()

  const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  const redirectUri = `${window.location.origin}/login`
  const searchParams = new URLSearchParams(window.location.search);
  const role = searchParams.get('role');


  if (!companyIdOrderIdHash) {
    const match = window.location.pathname.match(/\/closings\/([^/]+)/);
    companyIdOrderIdHash = match ? match[1] : undefined;
  }

  const onRedirectCallback = (appState: any) => {
    console.log('[auth0] onRedirectCallback() appState', appState)
    navigate(appState?.returnTo || window.location.pathname + window.location.search);
  };

  // Properties needed to allow mfa.  Also check the boxes in auth0 ui.
  let scope = getScopes(organization.MfaRequired)
  const audience = getAudience(organization.MfaRequired)

  const auth0Config: Auth0ProviderOptions = {
    domain,
    clientId,
    redirectUri,
    audience,
    scope,
    organization: organization.Auth0OrganizationId,
    role,
    companyIdOrderIdHash,
  }

  return (
    <Auth0Provider {...auth0Config} onRedirectCallback={onRedirectCallback}>
      {children}
      <StoreAuthenticated/>
    </Auth0Provider>
  );
};



const StoreAuthenticated = () => {
  // IMPROVE (maybe): Grab the user's name here and store that in local storage
  // and the landing page could use it to say "WELCOME BACK TODD!"
  const { isAuthenticated } = useAuth0()
  const [hasAuthenticated, setHasAuthenticated] = useLocalStorage('hasAuthenticated', false)

  useEffect(() => {
    if (isAuthenticated) {
      setHasAuthenticated(true)
    }
  }, [ isAuthenticated, hasAuthenticated, setHasAuthenticated ])

  return null;
}
