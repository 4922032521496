import { Box, CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { TelemetryCustomization, AppUrls, track, useGetConfig } from '../logic';
import { Auth0ProviderWithHistory } from '../logic/Auth0ProviderWithHistory';
import { getPageUrl } from '../utils/getPageUrl';
import { OrganizationProvider } from './OrganizationProvider';
import { RoutesComponent } from './Routes';
import { LoadingScreen } from './shared';
import { ErrorBoundary } from './shared/ErrorBoundary';
import { ErrorDialog } from './shared/ErrorDialog';
import '../../App.css'
import ScrollToTop from './shared/ScrollToTop';
import { ExternalLinkCapturer } from './shared/ExternalLinkCapturer';

export function App() {
  const drawerWidth = 240
  const drawerHeight = 70

  const { loading, error, data } = useGetConfig(getPageUrl())

  if (loading) {
    return <LoadingScreen />

  } else if (error) {
    console.log(`[api] useGetConfig() ERROR: ${JSON.stringify(error)}`);

    track('error', 'useGetConfig', {}, {
      message: JSON.stringify(error),
      component: 'App',
    })

    const redirectToRequestInvite = () => window.location.assign(AppUrls.requestInvite())

    return (
      <ErrorDialog
        error={error}
        onTryAgain={redirectToRequestInvite}
        waitOnAuth0={false}
        tryAgainText='Request Invitation'
      />
    )
  }

  if ('redirectTo' in data!) {
    window.location.assign(data!.redirectTo!)
    return <></>
  }

  // companyIdOrderIdHash only comes back when it's an invite link
  const { org, auth0TicketId, companyIdOrderIdHash} = data!

  return (
    <ExternalLinkCapturer>
      <BrowserRouter>
        <ScrollToTop />
        <Auth0ProviderWithHistory organization={org} companyIdOrderIdHash={companyIdOrderIdHash}>
          <ErrorBoundary>
            <OrganizationProvider organization={org}>

              <Box sx={{ height: '100%', minHeight: '100vh' }}>

                {/* This needs to be called inside of Auth0ProviderWithHistory to allow
                    for adding Auth0 user data to error tracking events. Renders nothing.
                */}
                <TelemetryCustomization />

                <CssBaseline />

                <Box
                  component="main"
                  sx={{
                    display: { xs: 'block', sm: 'flex' },
                    height: '100%', minHeight: '100vh',
                    width: '100%', mb: '56px',
                    marginTop: { xs: `${drawerHeight}px`, sm: '0'},
                  }}
                >
                  <RoutesComponent
                    drawerWidth={drawerWidth}
                    drawerHeight={drawerHeight}
                    auth0TicketId={auth0TicketId}
                    companyIdOrderIdHash={companyIdOrderIdHash}
                  />
                </Box>
              </Box>
            </OrganizationProvider>
          </ErrorBoundary>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ExternalLinkCapturer>
  )
}
